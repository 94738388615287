<template>
  <v-app class="text-center">
    <v-app-bar app color="#0070d1" light elevation="0" height="130">
      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="105"
            :src="LogoLiderBCI"
            max-width="155"
            style="margin-left: 2rem"
          />
        </div>
      </router-link>

      <v-spacer></v-spacer>

    




    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LogoLiderBCI from "@/assets/images/LogoLiderBCI.png";

export default {
  components: {
    Footer,
  },
  data: () => ({
    LogoLiderBCI,
  }),
};
</script>